import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const OurDoctorsTwo = () => {
  return (
    <div className="doctors-area meet-our-doctors ptb-70">
      <div className="container">
        <div className="section-title">
          <ScrollAnimation animateIn="fadeInUp" offset="100">
            <h2>Meet Our Doctors</h2>
          </ScrollAnimation>
        </div>

        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-rajneesh-juneja.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>Dr. Rajneesh Juneja</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Rajneesh Juneja did his MBBS from MG Medical College, Indore,
              Madhya Pradesh. He did his post graduation in Radiodiagnosis from
              JLN Hospital & Research Centre, Bhilai and DCA, Delhi. He did
              three years Senior Residency from largest MCD hospital in Delhi.
              He is a senior consultant and has been associated with Dr. Doda’s
              Diagnostics and Healthcare for more than 15 years. Dr. Juneja is
              well versed with X-rays, Special Investigations, USG/ Color
              Doppler and CT with special interest in Interventional, Uro Gynae
              and Vascular Radiology.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-sarika-jain.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>Dr. Sarika Jain</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Sarika Jain graduated from Gajra Raja Medical College, Gwalior
              and did her MD in Radiodiagnosis from Netaji Subhash Chandra Bose
              Medical College, Jabalpur followed by DNB. She did her Senior
              Residency from Bombay Hospital, Mumbai & GB Pant Hospital, New
              Delhi. She joined Dr. Doda’s Diagnostics and Healthcare as a
              Consultant Radiologist in 2015. Her expertise lies in X-ray,
              Ultrasound, CT & MRI reporting especially CT and MR angiograms.
              Her special area of interest is cross sectional imaging.
            </p>
          </div>
        </div>

        {/* <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-sarit-kakar.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>Dr. Sarit Kakar</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Sarit Kakar is a graduate from Kasturba Medical College,
              Manipal & did his post graduation in Radiodiagnosis from Diwan
              Chand Satyapal Imaging Centre. He has over 16 years of practical
              experience in Radiology both in India and abroad. In past few
              years he has diagnosed various malignancies and their recurrences
              post chemo/post radiotherapy. He is proficient in diagnosing
              malignancies of breast, abdominal, prostate and also
              gynecological, neurological and MSK malignancies. His expertise
              lies in MRI and CT reporting especially Neuroimaging, Abdominal
              imaging, Musculoskeletal and various angiographies.
            </p>
          </div>
        </div> */}

        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-manjusha-nath.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>Dr. Manjusha Nath</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr Manjusha Nath joined Dr. Doda's Diagnostics & Healthcare in the
              year 2013. She did both her graduation & MD in Radiodiagnosis from
              Guwahati Medical College and Hospital, Assam. She did her senior
              residency from VMMC and Safdarjung Hospital, New Delhi. Her areas
              of expertise are in X-rays, Ultrasound, CT scan and Mammography
              including special investigations.
            </p>
          </div>
        </div>

        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-shalini-jain.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>Dr. Shalini Jain</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Shalini Jain is a graduate from JSS Medical College, Mysore.
              She has done her DNB training in Radiodiagnosis from Oswal Cancer
              Hospital, Ludhiana. Subsequently, she pursued her senior residency
              and junior consultancy from the Cancer Hospital. She has been
              associated with Dr. Doda's Diagnostics Centre for more than 10
              years and has enormous experience in various types of Ultrasounds
              (including Doppler's, 3D-4D, Small parts and Intra-cavitatory).
              Her expertise lies in Breast imaging - she has reported nearly
              10,000 cases of Mammography and Breast ultrasounds. She excels in
              interventional procedures like aspirations and FNAC's by targeting
              subcentimeter lesions.
            </p>
          </div>
        </div>

        {/* <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-tina-verma.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>Dr. Tina Verma</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Tina Verma is a Senior Consultant Fetal Medicine with MS in
              Obs & Gynae, FNB High Risk Pregnancy & Perinatology from Fernandez
              Hospital, Hyderabad. She has done Fellowship in obstetric Scan
              from Mediscan, Chennai and is also certified as NT specialist by
              Fetal Medicine Foundation (London, UK) since 2013. She has more
              than 10 years of clinical experience with special interest in
              Fetal Echocardiography, Fetal neurosonography, Level II obstetric
              scan, NT scan, Genetic counselling and Prenatal diagnosis of
              Congenital abnormalities. She has memberships to the Society of
              Fetal Medicine, India , AOGD(Delhi), Member- ISUOG London & Fetal
              Medicine Subcommitee AOGD. She has been awarded Recognition of
              Dedicated Life From IMA, 2009. She has presented and published
              scientific papers in many International, National & state
              conferences, and CME’s
            </p>
          </div>
        </div> */}

        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <img src="/images/dr-gunpreet-anand-juneja.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>Dr. Gunpreet Anand Juneja</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Gunpreet Anand Juneja has been a Consultant Pathologist at Dr.
              Doda's Diagnostics and Healthcare, New Delhi since two and a half
              years and is also the associate quality manager. She is a
              dedicated pathologist with over 6 years of experience in Clinical
              Laboratory as well in Medical Institute and Research Centre. She
              has special interest in Histopathology and Cytopathology. She has
              a teaching experience of more than 3 years in Medical College and
              has conducted various academic activities. She has been a Guide
              and a Trainee of an undergraduate in ICMR project. She is trained
              in Quality management and Internal audit in Laboratories as per
              ISO 15189:2022.
            </p>
          </div>
        </div>

        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <img src="/images/Dr-Ayan-Saha.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>Dr. Ayan Saha</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Ayan Saha is a Consultant Radiologist at Dr. Doda’s
              Diagnostics & Healthcare in Delhi. He did his MD from Maulana Azad
              Medical College, New Delhi in 2017 and Senior Residency at AIIMS,
              New Delhi. Ayan Saha has also received his secondary DNB degree in
              the year 2021 and European Diploma in Radiology from the European
              Board of Radiology in 2023. He is an honorary diplomate of the
              Indian College of Radiology. He has been a life member of the
              Indian Radiological & Imaging Association and the Indian College
              of Radiology. His core areas of interest include cross-sectional
              imaging (CT& MRI) with special interest in Musculoskeletal imaging
              including MRI and HR-USG, he also expertise’s in coronary
              angiography studies and oncoimaging. He strives for academic
              excellence, has won distinctions as a student and has presented
              many research papers at various International & National
              conferences.
            </p>
          </div>
        </div>

        <div className="row align-items-center mb-50">
          <div className="col-md-3 nk__text-center">
            <img src="/images/Dr-Tej-Prakash-Gupta.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-left">
            <ScrollAnimation animateIn="fadeInRight" offset="100">
              <h3>Dr. Tej Prakash Gupta</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr. Tej Prakash Gupta, a senior consultant radiologist and fetal
              medicine expert, has over 13 years of clinical experience,
              specializing in fetal medicine and musculoskeletal ultrasounds. He
              earned his degrees from SMS Medical College, Jaipur, and is a
              member of AIUM, IRIA, Fetal Medicine Foundation London, and SFM.
              Trained in pregnancy interventions such as Amniocentesis, CVS,
              fetal reduction etc. at CIMAR Kochi, he holds certifications from
              FMF London and UCAM, University of Murcia, Spain. With over 8
              years of experience, he's proficient in fetal echocardiography,
              having trained at Mediscan Chennai.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-50 flex-row-reverse">
          <div className="col-md-3 nk__text-center">
            <img src="/images/Dr-P-Soumya-Singh.png" alt="Doctor" />
          </div>
          <div className="col-md-9 nk__text-right">
            <ScrollAnimation animateIn="fadeInLeft" offset="100">
              <h3>Dr. P Soumya Singh</h3>
            </ScrollAnimation>
            <p className="nk__overview_info">
              Dr P Soumya Singh is a highly qualified specialist in Maternal
              Fetal Medicine. She did her MS Obstetrics and Gynecology from SMS
              medical college, Jaipur. With keen interest in Fetal medicine she
              pursued her super specialization (FNB-MFM) in Maternal Fetal
              Medicine from MAMC, Delhi and associated Lok Nayak Hospital. She
              added to her expertise with a fellowship in Advanced Ultrasound in
              pregnancy, from Deenanath Mangeshkar Hospital,Pune. With an
              overall clinical experience of 10 years and more than 5 years of
              experience in fetal medicine. Dr Soumya is skilled not only in
              detecting abnormalities in first and second trimester ultrasound
              but also in counseling couples on how to proceed further in their
              pregnancy with any past or present fetal abnormalities. She excels
              in monitoring fetal growth and development at every stage,
              offering comprehensive care in obstetric ultrasounds including
              Fetal neurosonogram, echocardiography and Fetal invasive
              procedures including Amniocentesis, CVS, cordocentesis and IUBT.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDoctorsTwo;
